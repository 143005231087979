import { urlEncodeBody } from 'utils';
import { createFormData, checkSuccess } from 'utils/requests';
import { fetchWithToken } from '.';

const mapProfile = data => ({
  email: data.email,
  firstName: data.first_name,
  lastName: data.last_name,
  avatar: data.avatar,
  phone: data.phone,
  passwordSet: !data.registered_social
});

export async function getProfile(): Promise<Profile> {
  const response = await fetchWithToken('/broker_site/profile');
  const data = await response.json();

  checkSuccess(data);

  const profile = mapProfile(data);
  const sells = data.sells.map(item => ({
    id: item.id,
    listing: item.listing,
    listingId: item.listing_id,
    sellProcessId: item.sell_process_id,
    created: item.created,
    formData: typeof item.data === 'string' ? JSON.parse(item.data) : item.data
  }));
  const buys = data.buys.map(item => ({
    id: item.id,
    listing: item.listing,
    listingId: item.listing_id,
    buyProcessId: item.buy_process_id,
    created: item.created,
    status: item.status,
    link: item.link,
    formData: typeof item.data === 'string' ? JSON.parse(item.data) : item.data
  }));

  return { ...profile, sells, buys };
}

export async function updateProfile(
  values: Omit<Partial<ProfileFormValues>, 'avatar'> & { avatar?: File }
): Promise<Pick<Profile, 'avatar'>> {
  const body = createFormData({
    first_name: values.firstName,
    last_name: values.lastName,
    phone: values.phone,
    avatar: values.avatar
  });
  const response = await fetchWithToken('/broker_site', { method: 'PATCH', body });
  const data = await response.json();

  checkSuccess(data);

  return { avatar: data.profile.avatar };
}

export async function updatePassword(values: UpdatePasswordForm): Promise<void> {
  const body = urlEncodeBody({
    old_password: values.oldPassword,
    password: values.password
  });
  await fetchWithToken('/broker_site/password', { method: 'PATCH', body });
}

type ProfileFormValues = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  avatar?: string | null;
};

type UpdatePasswordForm = {
  oldPassword: string;
  password: string;
};
