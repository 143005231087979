import React, { useContext } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { Image } from 'components';
import { IMAGE_SIZES } from 'consts';
import noImage from 'assets/images/placeholder_no_image.jpg';
import ProfileContext from './context';

const getPropertyPhoto = data => {
  const uploadedPhoto = data.property_images?.[0]?.url;

  if (uploadedPhoto) {
    return <Image src={uploadedPhoto} className="listing-info__photo" />;
  }

  if (data.propertyPhoto) {
    return (
      <Image
        cloudFront={{ size: IMAGE_SIZES.medium, key: data.propertyPhoto }}
        className="listing-info__photo"
      />
    );
  }

  return <img src={noImage} alt="mini-propetry" className="listing-info__photo" />;
};

const Sells = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <div className="profile-listings">
      <h3 className="profile-section__title">Your Listings</h3>
      {profile?.sells?.map(({ id, created, formData = {} }) => (
        <StyledSellItem key={id} className="listing-info">
          {getPropertyPhoto(formData)}
          <div className="listing-info__details">
            <h3 className="listing-info__address">{formData.propertyAddress || formData.google_address}</h3>
            {created && (
              <div className="listing-info__item">
                <span className="label">Date:</span> {dayjs.unix(created).format('MM/DD/YY')}
              </div>
            )}
            <div className="listing-info__item">
              <span className="label">Purchased package:</span>{' '}
              {formData.params?.package || 'N/A'}
            </div>
          </div>
        </StyledSellItem>
      ))}
    </div>
  );
};

export default Sells;

const StyledSellItem = styled.div`
  width: 100%;

  &.listing-info {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    background: ${props => props.theme.colors.cementGray};
    border-radius: ${props => props.theme.misc.borderRadius};

    & + & {
      margin-top: 24px;
    }
  }

  .listing-info__photo {
    background-image: url(${noImage});
    flex-shrink: 0;
  }

  .listing-info__photo,
  .img-error {
    margin-right: 27px;
    width: 148px;
    height: 148px;
    border-radius: ${props => props.theme.misc.borderRadius};
    object-fit: cover !important;
    border: 1px solid rgba(0, 0, 0, 0.1);

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 48px;
      height: 48px;
    }
  }

  .listing-info__address {
    margin: 4px 0 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .listing-info__item {
    margin: 12px 0;
    font-size: 14px;
    line-height: 24px;

    .label {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .listing-info__progress {
    font-size: 12px;
  }

  .listing-info__edit {
    margin-left: auto;
    .icon {
      fill: ${props => props.theme.colors.violet};
    }
  }
`;
