import React from 'react';
import styled from '@emotion/styled';

const ListingContainer = ({ children }) => {
  return <StyledListingContainer className="profile-container">{children}</StyledListingContainer>;
};

export default React.memo(ListingContainer);

const StyledListingContainer = styled.div`
  display: flex;
  padding: 16px;
  background: ${props => props.theme.colors.cementGray};
  border-radius: ${props => props.theme.misc.borderRadius};
  position: relative;

  & + & {
    margin-top: 16px;
  }

  .profile {
    &__details {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      margin: 0 0 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &__info {
      margin: 0 0 12px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;

      &-label {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &__btn-group {
      margin: 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__btn {
      padding: 0 16px;
      font-size: 12px;
      line-height: 120%;
    }

    &__btn-link {
      display: inline-block;
      height: 32px;
      background: ${props => props.theme.colors.primary};
      padding: 0 16px;
      font-size: 12px;
      line-height: 32px;
      color: #fff;
      border-radius: ${props => props.theme.misc.borderRadius};
      text-decoration: none;
    }

    &__link,
    &__status {
      font-size: 14px;
      line-height: 24px;
    }

    &__status {
      &.accepted {
        color: ${props => props.theme.colors.lightGreen};
      }
      &.rejected {
        color: ${props => props.theme.colors.red};
      }
      &.active {
        color: ${props => props.theme.colors.blue};
      }
    }

    &__link {
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
    }
  }

  .profile__photo, .img-error {
    margin-right: 28px;
    width: 148px;
    height: 148px;
    border-radius: ${props => props.theme.misc.borderRadius};
    object-fit: cover !important;
    flex-shrink: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 20px;
    flex-direction: column;

    .profile {
      &__top {
        margin: 0 0 12px;
        flex-flow: row;
        flex-wrap: wrap;
      }
      &__btn-group {
        flex-direction: column;
        margin: 16px 0 0;
      }
      &__btn-link {
        width: 100%;
        text-align: center;
      }
      &__link-download {
        margin: 20px 0 0;
      }
    }

    .profile__photo, .img-error {
      width: 48px;
      height: 48px;
      margin-right: 18px;
    }
  }
`;
