import React, { useContext } from 'react';

import styled from '@emotion/styled';
import { Button } from 'components';
import AccountSettings from './AccountSettings';
import PasswordSettings from './PasswordSettings';
import ProfileContext from './context';

interface EditAccountProps extends React.HTMLAttributes<HTMLDivElement> {
  path?: string;
}

const EditAccount: React.FC<EditAccountProps> = () => {
  const { profile, setProfile, logout } = useContext(ProfileContext);

  return (
    <StyledEditAccount className="profile-wrapper">
      {profile && <AccountSettings profile={profile} setProfile={setProfile} />}
      <div className="profile-section profile-logout">
        <h3 className="profile-section__title">Sign Out</h3>
        <p className="profile-section__logout-description">
          Click on the button below to sign out. If you sign out, you can sign back in anytime.
        </p>
        <Button primary className="profile-section__btn" onClick={logout}>
          Sign Out
        </Button>
      </div>
      <PasswordSettings hideCurrentPassword={profile?.passwordSet === false} />
    </StyledEditAccount>
  );
};

const StyledEditAccount = styled.div`
  display: flex;
  max-width: 1157px;
  margin: 0 auto;
  flex-direction: column;

  .profile {
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
    }
  }
  .profile-section {
    margin: 0 0 36px;
    &__logout-description {
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 16px;
    }
    &__input {
      width: 100%;
      margin: 0 0 12px;
    }
    &__input-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 12px;
    }
    &__btn {
      min-width: 75px;
      margin: 4px 0 0;
    }
  }

  .profile-account {
    grid-area: account;
  }

  .profile-password {
    grid-area: password;
  }

  .profile-logout {
    grid-area: logout;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .profile {
      &__title {
        margin: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .profile-section {
      &__logout-description {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 16px;
      }
      &__input-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }
  }
`;

export default EditAccount;
